import './login-header.scss';
import LogoKMQ from "../../../assets/logo/KnowMeQ_logo.svg";
import LogoFleming from "../../../assets/logo/Fleming-KnowMeQ-Logo.png";
import {useLocation, useNavigate} from "react-router-dom";

const LoginHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return <div className={'login-header'}>
    <img
      className={window.location.hostname.includes('flemingcollege')
        ? 'login-header-logo-fleming'
        : 'login-header-logo'
    }
      src={window.location.hostname.includes('flemingcollege') ? LogoFleming : LogoKMQ}
      alt={'kmq-logo'}
      onClick={
        location.pathname.includes('login')
          ? () => navigate('/login')
          : () => navigate('/')
      }
    />
  </div>
}

export default LoginHeader;