import "./parse-resume.scss";
import { useContext, useEffect, useState } from "react";
import { deepClone } from "../../../lib/resume-parser/deep-clone";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import { ResumeContext } from "../../../hooks/Context/ResumeContext";
import { useNavigate } from "react-router-dom";
import { useMutateRecommendation } from "../../../api/normalization/useMutateRecommendation";
import { CheckCircle, WarningCircle } from "@phosphor-icons/react";
import CplApplicantProfile from "../../../components/Resume/CplApplicantProfile/CplApplicantProfile";
import Dropzone from "../../../components/Resume/Dropzone/Dropzone";
import Loading from "../../../components/UI/Loading/Loading";
import { PortfolioContext } from "../../../hooks/Context/PortfolioContext";
import Notification from "../../../components/UI/Notification/Notification";
import AlertModal from "../../../components/UI/Modals/AlertModal/AlertModal";
import SubmittedProfileOpacityView from "../../../components/Resume/CplApplicantProfile/components/SubmittedProfileOpacityView";
import { resumeToProfile } from "../../../lib/data-formatting/resumeToProfile";
import { useQueryUserInfo } from "../../../api/admin/useQueryUserInfo";
import { portfolioToResume } from "../../../lib/data-formatting/portfolioToResume";
import { ButtonTypes } from "../../../constants/ui/types/ButtonTypes";
import ProgressBar from "./progress-bar/ProgressBar";
import CplApplicantSubmittedProfile from "../../../components/Resume/CplApplicantSubmittedProfile/CPLApplicantSubmittedProfile";
import { EDUCATION_STATE, RESUME_STATE, REVIEW_STATE, WORK_EXPERIENCE_STATE } from "../../../constants/resume/default";
import {UserContext} from "../../../hooks/Context/UserContext";

const ParseResume = () => {
  const [user] = useContext(UserContext);
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const [parsedResume, setParsedResume] = useContext(ResumeContext);
  const [currentPage, setCurrentPage] = useState(RESUME_STATE);
  const [manualEntryMode, setManualEntryMode] = useState<boolean>(false);
  const [currentWorkIndex, setCurrentWorkIndex] = useState<number>(0);
  const [currentEduIndex, setCurrentEduIndex] = useState<number>(0);
  const [isSubPageFormValid, setIsSubPageFormValid] = useState<boolean>(false);
  const [isReviewFormValid, setIsReviewFormValid] = useState<boolean>(false);
  const [pendingAdd, setPendingAdd] = useState<null | 'work' | 'education'>(null);
  const {
    mutate: postRecommendation,
    isSuccess: isRecommendationSuccess,
    isPending: isRecommendationLoading,
    isError: isRecommendationError,
  } = useMutateRecommendation(true);
  const { refetch } = useQueryUserInfo({});
  const [welcomeModalOpen, setWelcomeModalOpen] = useState<boolean | null>(null);
  const [programsModalOpen, setProgramsModalOpen] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [showClearResumeAlert, setShowClearResumeAlert] = useState(false);
  const [showFileSuccessModal, setShowFileSuccessModal] = useState(false);
  const [alertIncompleteModalOpen, setAlertIncompleteModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isRecommendationSuccess) {
      refetch().then((response) => {
        if (response.data) {
          setParsedResume(portfolioToResume(response.data));
          setPortfolio(response.data);
        }
      });
      setProgramsModalOpen(true);
    }
  }, [isRecommendationSuccess]);

  useEffect(() => {
    if (welcomeModalOpen === null && portfolio.status && (!portfolio.id || !portfolio.portfolio_email)) {
      setWelcomeModalOpen(true);
    }
    setIsSubPageFormValid(true);
  }, [portfolio, welcomeModalOpen]);

  useEffect(() => {
    const isValid = validateCurrentSection();
    setIsSubPageFormValid(isValid);
    const isValidEntireForm = validateForm();
    setIsReviewFormValid(isValidEntireForm);
  }, [parsedResume, currentPage, currentEduIndex, currentWorkIndex]);

  const validateCurrentSection = (): boolean => {
    if (currentPage === WORK_EXPERIENCE_STATE) {
      const work = parsedResume.work_experience[currentWorkIndex];
      return Boolean(
        work?.job_title &&
        work.company &&
        work.start_date &&
        work.job_summary &&
        (work.current_job || work.end_date) &&
        work.location
      );
    } else if (currentPage === EDUCATION_STATE) {
      const edu = parsedResume.education[currentEduIndex];
      return Boolean(
        edu?.program &&
        edu.institution_name &&
        edu.start_date &&
        edu.institution_type &&
        (edu.currently_enrolled || edu.end_date) &&
        edu.location
      );
    }
    return true;
  };

  const validateForm = (): boolean => {
    const isValidWork = parsedResume.work_experience.every(work => Boolean(
      work?.job_title &&
      work.company &&
      work.start_date &&
      work.job_summary &&
      (work.current_job || work.end_date) &&
      work.location
    ));
    
    const isValidEducation = parsedResume.education.every(edu => Boolean(
      edu?.program &&
      edu.institution_name &&
      edu.start_date &&
      edu.institution_type &&
      (edu.currently_enrolled || edu.end_date) &&
      edu.location
    ));
    
    return isValidWork && isValidEducation;
  };


  const handleAddWork = () => {
    if (validateCurrentSection()) {
      const newResume = deepClone(parsedResume);
      newResume.work_experience.push({ company: "", job_title: "", job_summary: "" });
      setParsedResume(newResume);
      setCurrentWorkIndex(newResume.work_experience.length - 1);
    } else {
      setPendingAdd('work');
      setAlertIncompleteModalOpen(true);
    }
  };

  const handleAddEducation = () => {
    if (validateCurrentSection()) {
      const newResume = deepClone(parsedResume);
      newResume.education.push({ program: "" });
      setParsedResume(newResume);
      setCurrentEduIndex(newResume.education.length - 1);
    } else {
      setPendingAdd('education');
      setAlertIncompleteModalOpen(true);
    }
  };

  const handleProceedAddSubItem = () => {
    if (pendingAdd === 'work') {
      const newResume = deepClone(parsedResume);
      newResume.work_experience.push({ company: "", job_title: "", job_summary: "" });
      setParsedResume(newResume);
      setCurrentWorkIndex(newResume.work_experience.length - 1);
    } else if (pendingAdd === 'education') {
      const newResume = deepClone(parsedResume);
      newResume.education.push({ program: "" });
      setParsedResume(newResume);
      setCurrentEduIndex(newResume.education.length - 1);
    }
    setPendingAdd(null);
    setAlertIncompleteModalOpen(false);
  };

  const goToNextPage = () => {
    if (currentPage === WORK_EXPERIENCE_STATE && currentWorkIndex < parsedResume.work_experience.length - 1) {
      setCurrentWorkIndex(currentWorkIndex + 1);
    } else if (currentPage === EDUCATION_STATE && currentEduIndex < parsedResume.education.length - 1) {
      setCurrentEduIndex(currentEduIndex + 1);
    } else {
      const pageOrder = [RESUME_STATE, WORK_EXPERIENCE_STATE, EDUCATION_STATE, REVIEW_STATE];
      const currentIndex = pageOrder.indexOf(currentPage);
      if (currentIndex < pageOrder.length - 1) {
        const nextPage = pageOrder[currentIndex + 1];
        setCurrentPage(nextPage);
        if (nextPage === REVIEW_STATE) {
          setReviewModalOpen(true);
        }
      }
    }
  };

  const goToPreviousPage = () => {
    if (currentPage === WORK_EXPERIENCE_STATE && currentWorkIndex > 0) {
      setCurrentWorkIndex(currentWorkIndex - 1);
    } else if (currentPage === EDUCATION_STATE && currentEduIndex > 0) {
      setCurrentEduIndex(currentEduIndex - 1);
    } else if (currentPage === WORK_EXPERIENCE_STATE && currentWorkIndex === 0) {
      setShowClearResumeAlert(true);
    } else {
      const pageOrder = [RESUME_STATE, WORK_EXPERIENCE_STATE, EDUCATION_STATE, REVIEW_STATE];
      const currentIndex = pageOrder.indexOf(currentPage);
      if (currentIndex > 0) {
        setCurrentPage(pageOrder[currentIndex - 1]);
      }
    }
  };

  const onProceedClearResume = () => {
    setParsedResume({ basic_info: {}, work_experience: [{ company: '', job_title: '', job_summary: '' }], education: [{ program: '' }] });
    setShowClearResumeAlert(false);
    setCurrentPage(RESUME_STATE);
  };

  const handleSubmit = () => {
    postRecommendation({ profile: resumeToProfile(parsedResume), portfolio_id: portfolio.id });
  };

  const handleManualFill = () => {
    setManualEntryMode(true);
    setCurrentPage(WORK_EXPERIENCE_STATE);
  };

  const handleFileUploadSuccess = () => {
    setShowFileSuccessModal(true);
    setCurrentPage(WORK_EXPERIENCE_STATE);
  };

  const renderCurrentPage = () => {
    if (portfolio.id && portfolio.portfolio_email) {
      return (
        <CplApplicantSubmittedProfile portfolio={portfolio} />
      );
    }
    if (currentPage === WORK_EXPERIENCE_STATE && currentWorkIndex >= parsedResume.work_experience.length) {
      setCurrentWorkIndex(parsedResume.work_experience.length - 1);
    }

    if (currentPage === EDUCATION_STATE && currentEduIndex >= parsedResume.education.length) {
      setCurrentEduIndex(parsedResume.education.length - 1);
    }

    switch (currentPage) {
      case RESUME_STATE:
        return (
          <div>
            <div className="edit-resume-my-profile-heading">
              Choose how you'd like to proceed: manually input resume or upload it to pre-fill the form with extracted information.
              Before submitting, please take a moment to review the form for any errors, missing fields, or validation requirements.
            </div>
            <Dropzone onFileUploaded={handleFileUploadSuccess} onManualFill={handleManualFill} />
          </div>
        );
      case WORK_EXPERIENCE_STATE:
        return (
          <CplApplicantProfile
            type={WORK_EXPERIENCE_STATE}
            isRecommendationError={isRecommendationError}
            handleAddWork={handleAddWork}
            handleAddEducation={handleAddEducation}
            index={currentWorkIndex}
            setCurrentWorkIndex={setCurrentWorkIndex}
            setCurrentEduIndex={setCurrentEduIndex}
          />
        );
      case EDUCATION_STATE:
        return (
          <CplApplicantProfile
            type={EDUCATION_STATE}
            isRecommendationError={isRecommendationError}
            handleAddWork={handleAddWork}
            handleAddEducation={handleAddEducation}
            index={currentEduIndex}
            setCurrentWorkIndex={setCurrentWorkIndex}
            setCurrentEduIndex={setCurrentEduIndex}
          />
        );
      case REVIEW_STATE:
        return (
          <CplApplicantProfile
            type={REVIEW_STATE}
            isRecommendationError={isRecommendationError}
            handleAddWork={handleAddWork}
            handleAddEducation={handleAddEducation}
          />
        );
      default:
        return <div>Invalid page</div>;
    }
  };

  return (
    <div>
      <Loading
        loading={isRecommendationLoading}
        showProgress={user?.permissions?.includes("is_marketplace_user")}
      />
      {!portfolio.id || !portfolio.portfolio_email ? (
        <Notification>
          Please review your information carefully before submitting. You cannot edit your submission afterwards.
        </Notification>
      ) : (
        <Notification>
          You cannot edit your submission. Please contact your school advisor for any changes.
        </Notification>
      )}
      <div className="edit-resume">
        {!(portfolio.id && portfolio.portfolio_email) && <ProgressBar currentPage={currentPage} />}
        {(portfolio.id && portfolio.portfolio_email && !programsModalOpen) ? <SubmittedProfileOpacityView /> : null}
        <div className="edit-resume-title">My Profile</div>
        <div>{renderCurrentPage()}</div>
        <div className="edit-resume-bottom-navigation">
          {currentPage !== 'Resume' && <ButtonKMQ onClick={goToPreviousPage} type={ButtonTypes.Secondary}>Back</ButtonKMQ>}
          {currentPage !== 'Review' && currentPage !== 'Resume' && <ButtonKMQ onClick={goToNextPage} disabled={!isSubPageFormValid}>Continue</ButtonKMQ>}
          {currentPage === 'Review' && <ButtonKMQ onClick={handleSubmit} disabled={!isReviewFormValid}>Submit</ButtonKMQ>}
        </div>
      </div>
      <AlertModal
        open={!!welcomeModalOpen}
        onClose={() => setWelcomeModalOpen(false)}
        title="Welcome to our Course Credits platform!"
        text="We're excited to help you take the next step in your career journey. To get started, you'll need to set up your profile. You can either manually input your resume information or upload your resume file to pre-populate the fields."
        proceedText="Let’s get started"
        onProceed={() => setWelcomeModalOpen(false)}
      />
      <AlertModal
        icon={<CheckCircle size={56} color="#212121" />}
        open={programsModalOpen}
        onClose={() => setProgramsModalOpen(false)}
        title="Application submitted!"
        text="Your application has been successfully submitted! Click the button below to check your results."
        proceedText="See My Programs"
        onProceed={() => {
          if (user?.permissions?.includes("is_marketplace_user")) {
            navigate('/cpl-applicant/marketplace')
          } else {
            navigate('/cpl-applicant/my-programs')
          }
        }}
        closeText="Close"
      />
      <AlertModal
        open={showClearResumeAlert}
        onClose={() => setShowClearResumeAlert(false)}
        text="If you go back to the previous page all the filled out information will be lost and you will have to restart the process. Are you sure you want to proceed?"
        proceedText="Yes, proceed"
        onProceed={onProceedClearResume}
        title="Warning"
        closeText="No, cancel"
        icon={<WarningCircle size={56} color="#212121" />}
      />
      <AlertModal
        open={reviewModalOpen}
        onClose={() => setReviewModalOpen(false)}
        title="Review your information"
        text="Please review your information carefully before submitting. You cannot edit your submission afterwards."
        proceedText="Continue"
        onProceed={() => setReviewModalOpen(false)}
      />
      <AlertModal
        open={showFileSuccessModal}
        onClose={() => setShowFileSuccessModal(false)}
        icon={<CheckCircle size={56} color={'#212121'} />}
        text={"Your resume has been successfully uploaded. Please continue to fill out the information."}
        proceedText={"Close"}
        onProceed={() => setShowFileSuccessModal(false)}
      />
      <AlertModal
          open={alertIncompleteModalOpen}
          onClose={() => setAlertIncompleteModalOpen(false)}
          text="Please fill out all areas in the form before adding a new work experience."
          proceedText="Yes, proceed"
          onProceed={handleProceedAddSubItem}
          title="Incomplete Form"
          closeText="No, cancel"
          icon={<WarningCircle size={56} color="#212121" />}
        />
    </div>
  );
};

export default ParseResume;
