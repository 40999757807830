import {useQuery} from '@tanstack/react-query';
import {marketplaceInstitutionsUrl} from '../../constants/api/endpoints';
import {useAxios} from '../../hooks/axios/useAxios';

interface QueryParams {
  portfolio_id?: string,
  location: [number, number] | undefined,
  searchParam: string
}

export const useQueryInstitutions = (params: QueryParams) => {
  const {
    portfolio_id,
    location,
    searchParam
  } = params;
  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(marketplaceInstitutionsUrl,
      {
        params: {
          portfolio_id,
          search_term: searchParam,
          location_lat: location ? location[0] : undefined,
          location_lon: location ? location[1] : undefined
        }
      })
      .then(response => response?.data?.education_institutions ?? response);
  };

  return useQuery({
    queryKey: ['institutions'],
    queryFn: handleRequest
  })
};
