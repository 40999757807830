import './faq.scss';
import {useQueryFAQ} from "../../api/faq/useQueryFAQ";
import Loading from '../../components/UI/Loading/Loading';
import ExpandableText from "../../components/UI/ExpandableText/ExpandableText";

const FAQ = () => {
  const {data, isLoading} = useQueryFAQ();

  if (isLoading) {
    return <Loading loading/>
  }

  return <div className={'faq'}>
    <div className={'faq-title'}>
      Frequently asked questions
    </div>

    {data.map((item: any) =>
      <div>
        <div className={'faq-heading'}>
          <div className={'faq-heading-title'}>
            {item.heading}
          </div>
          {item.sections.map((section: any) =>
            <ExpandableText
              title={section.sub_heading}
              expandableHtml={section.content}
            />
          )}
        </div>
      </div>
    )}
  </div>
}

export default FAQ;