import Loading from "../../../components/UI/Loading/Loading";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {jwtToJson} from "../../../lib/jwt/jwtToJson";
import {useToken} from "../../../hooks/jwt/useToken";

const Auth = () => {
  const navigate = useNavigate();
  const {setTokens} = useToken();
  const [stableRefresh, setStableRefresh] = useState<string>('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refresh = urlParams.get('refresh')
    if (refresh) {
      setTokens({refreshToken: '', accessToken: ''});
      localStorage.removeItem('refresh');
      setStableRefresh(refresh);
    } else {
      if (!stableRefresh) {
        navigate('/login');
      }
    }
  }, [])

  useEffect(() => {
    if (stableRefresh) {
      try {
        jwtToJson(stableRefresh);
        localStorage.setItem('refresh', stableRefresh);
        navigate('/cpl-applicant/my-programs')
      } catch (e) {
        navigate('/login');
      }
    }
  }, [stableRefresh])

  return <Loading loading/>
}

export default Auth;