import {Outlet, useNavigate, useLocation} from "react-router-dom";
import {ReactNode, useEffect, useState} from "react";
import "./layout.scss";

import Header from "../Headers/UserHeader/Header";
import MenuKMQ from "../UI/Menu/MenuKMQ";
import Footer from "../Footer";

interface MenuOption {
  name: string;
  icon: ReactNode;
}

const MenuLayout = ({menuOptions}: { menuOptions: MenuOption[] }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={"layout"}>
      <div className={"layout-header"}>
        <Header/>
      </div>

      <table className={"layout-content"}>
        <tr>
          <td className={"layout-content-menu"}>
            <MenuKMQ
              options={menuOptions.map((item: any, _: number) => {
                return {
                  ...item,
                  active: item.link === location.pathname,
                  onClick: () => navigate(item.link),
                };
              })}
            />
          </td>

          <td className={"layout-content-page"}>
            <Outlet/>
          </td>
        </tr>
      </table>

      <Footer/>
    </div>
  );
};

export default MenuLayout;
