import './header.scss';
import LogoKMQ from '../../../assets/logo/KnowMeQ_logo.svg';
import LogoFleming from "../../../assets/logo/Fleming-KnowMeQ-Logo.png";
import UserProfile from "../../UI/Profile/UserProfile";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryUser } from "../../../api/admin/useQueryAdmin";
import { useState } from 'react';
import AlertModal from '../../UI/Modals/AlertModal/AlertModal';
import { SignOut } from '@phosphor-icons/react';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useQueryUser();
  const [showDropdown, setShowDropdown] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState<boolean>(false);

  const handleLogout = () => {
    localStorage.removeItem('refresh');
    window.location.reload();
  }

  const navigateToProfile = () => {
    if (location.pathname.includes('/')) {
      navigate(`/${location.pathname.split('/')[1]}/my-profile`);
    }
  };

  return (
    <div className="header">
      <img
        className={window.location.hostname.includes('flemingcollege') ? 'header-logo-fleming' : 'header-logo'}
        src={window.location.hostname.includes('flemingcollege') ? LogoFleming : LogoKMQ}
        alt="kmq-logo"
        onClick={() => navigate('/')}
      />

      <div
        className="header-profile"
        onMouseEnter={() => setShowDropdown(true)}
        onMouseLeave={() => setShowDropdown(false)}
      >
        <UserProfile
          name={{
            first_name: data?.first_name ?? 'Administrator',
            last_name: data?.last_name ?? '',
          }}
          showText
        />
        {showDropdown && (
          <div className="dropdown-menu">
            <div className="dropdown-item" onClick={navigateToProfile}>
              My Profile
            </div>
            <div className="dropdown-item" onClick={() => setLogoutModalOpen(true)}>
              Logout
            </div>
          </div>
        )}
      </div>
      <AlertModal
        open={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        icon={<SignOut size={56} color="#212121"/>}
        title={'Log out?'}
        text={'Are you sure you want to log out? You will be signed out of your account.'}
        closeText={'Close'}
        proceedText={'Log out'}
        onProceed={handleLogout}
      />
    </div>
  );
};

export default Header;
