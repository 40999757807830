import './login.scss';
import InputKMQ from "../../components/UI/Input/InputKMQ";
import {ChangeEvent, KeyboardEventHandler, useContext, useEffect, useState} from "react";
import ButtonKMQ from "../../components/UI/Button/ButtonKMQ";
import {useLogin} from "../../api/login/useLogin";
import {UserContext} from "../../hooks/Context/UserContext";
import {useNavigate} from "react-router-dom";
import {useToken} from "../../hooks/jwt/useToken";
import {object, string, ValidationError} from "yup";
import Loading from "../../components/UI/Loading/Loading";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [user, setUser] = useContext(UserContext);
  const {mutate, isError, data, isSuccess, isPending} = useLogin();
  const {setTokens} = useToken();
  const [validationError, setValidationError] = useState<boolean>(false);

  let loginSchema = object({
    email: string().email().min(2).required(),
    password: string().min(2).required()
  });

  const handleSubmit = async () => {
    try {
      await loginSchema.validate({email, password});
      mutate({email, password})
      setValidationError(false);
    } catch (e) {
      setValidationError(true);
    }
  }
  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.keyCode === 13) {
      if (email.length > 2 && password.length > 2) {
        handleSubmit();
      }
    }
  }

  useEffect(() => {
    if (isSuccess && data?.data) {
      if (data?.data?.mfa_required) {
        setUser({email: ''});
        localStorage.setItem('user-email', email);
        navigate('/login/verification-code')
      } else {
        setUser({
          ...user,
        });
        setTokens({
          accessToken: data?.data?.access,
          refreshToken: data?.data?.refresh
        })
        localStorage.setItem('refresh', data?.data?.refresh)
        navigate('/');
      }
    }
  }, [isSuccess, data])

  return <div>
    <Loading loading={isPending}/>
    <div className={'login-form'}>
      <div className={'login-form-title'}>
        Log in
      </div>
      <InputKMQ
        value={email}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        type={'text'}
        placeholder={'Enter email'}
        label={'Email'}
        error={(validationError || isError) ? 'Invalid email.' : ''}
        onKeyDown={onKeyDown}
      />
      <div className={'login-form-password-wrap'}>
        <div
          className={'login-form-password-wrap-link'}
          onClick={() => navigate('/login/forgot-password')}
        >
          Forgot password?
        </div>
        <InputKMQ
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          type={'password'}
          placeholder={'Enter password'}
          label={'Password'}
          error={(validationError || isError) ? 'Invalid password. Please try again.' : ''}
          passwordShowIcon
          onKeyDown={onKeyDown}
        />
      </div>
      <div className={'login-form-sign-up'}>
        {'New user? '}
        <span className={'login-form-sign-up-link'} onClick={() => navigate('/login/sign-up')}>
        Sign up
      </span>
      </div>
      <div className={'login-form-footer'}>
        <ButtonKMQ onClick={handleSubmit}
                   disabled={!(email.length > 2 && password.length > 2)}>
          Continue
        </ButtonKMQ>
      </div>
    </div>
  </div>
}

export default Login;