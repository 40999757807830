export const postResumeParserUrl = 'resume-parsing/resume-parse';
export const postLoginUrl = 'user-management/login';
export const adminInfoUrl = 'user-management/user-profile';
export const loginMfaUrl = 'user-management/verification-code';
export const registerUrl = 'user-management/register';
export const forgotPasswordUrl = 'user-management/forgot-password';
export const resetPasswordUrl = 'user-management/reset-password-magic-link';
export const resetPasswordWithTokenUrl = 'user-management/reset-password';

export const postRefreshTokenUrl = 'user-management/token/refresh';

export const programsUrl = 'course-information/programs';

export const coursesUrl = 'course-information/courses'
export const marketplaceInstitutionsUrl = 'course-information/education-institutions'
export const evidencesUrl = 'core-plar-recommendation/evidence';
export const evidenceFeedbackUrl = 'core-plar-recommendation/evidence-feedback';
export const courseFeedbackUrl = 'core-plar-recommendation/course-recommendation-feedback';
export const assignPortfolioUrl = '/core-plar-recommendation/assign-portfolio';
export const finishPortfolioReviewUrl = '/core-plar-recommendation/portfolio-review';
export const userInfoUrl = 'core-plar-recommendation/portfolio';
export const applicantsUrl = 'core-plar-recommendation/portfolio-list';
export const reportUrl = 'core-plar-recommendation/evidence-report';
export const favouriteUrl = 'core-plar-recommendation/favourite-program'
export const faqUrl = 'faq/questions'
export const addToCartUrl = '/payments/course-recommendation-payment-status'
export const stripeSessionUrl = '/payments/stripe-checkout-session'
export const stripeSessionStatusUrl = '/payments/stripe-session-status'


export const assessmentListURL = 'assessment-engine/assessments/';


export const assessmentSubmitURL = 'assessment-engine/calculate/';


export const assessmentFinishLaterURL = 'assessment-engine/save_responses/';


export const assessmentFinishLaterGetURL = 'assessment-engine/responses/';


export const assessmentProgressGETURL = 'assessment-engine/assessments/';

export const assessmentQuestionDataGETURL = 'assessment-engine/assessment/'

export const assessmentResultGETURL = 'assessment-engine/results/';
